import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'PaymentInvoice',
  props: ['paymentAdviceRowDtl'],
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      loader: false,
      currentPage: 1,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      leName: null,
      ouName: null,
      adviceNo: null,
      refNo: null,
      supName: null,
      supNumber: null,
      bankName: null,
      bankAcc: null,
      paymentHdrId: null,
      paymentInvoiceData: [],
      paymentInvoiceFields: [
        {
          key: 'unit_name'
        },
        {
          key: 'description'
        },
        {
          key: 'project_name'
        },
        {
          key: 'invoice_date'
        },
        {
          key: 'gross_amount'
        },
        {
          key: 'line_seq'
        },
        {
          key: 'invoice_no'
        },
        {
          key: 'month'
        },
        {
          key: 'paid_adj_amount'
        },
        {
          key: 'net_balance_payable'
        },
        {
          key: 'paid_current_amount'
        }
      ]
    };
  },
  mounted() {
    if (this.paymentAdviceRowDtl) {
      this.leName=this.paymentAdviceRowDtl.le_name;
      this.ouName=this.paymentAdviceRowDtl.ou_name;
      this.adviceNo = this.paymentAdviceRowDtl.payment_advice_no;
      this.refNo=this.paymentAdviceRowDtl.payment_ref_no;
      this.supName=this.paymentAdviceRowDtl.supplier_name;
      this.supNumber=this.paymentAdviceRowDtl.supplier_num;
      this.bankName=this.paymentAdviceRowDtl.bank_name;
      this.bankAcc=this.paymentAdviceRowDtl.ac_no;
    }
    this.getPaymentInvoiceDetails(this.paymentAdviceRowDtl.ap_payment_advice_hdr_id)
  },
  methods: {
    getPaymentInvoiceDetails(ap_payment_advice_hdr_id) { 
      this.loader = true;
      this.$store
        .dispatch('adviceStatus/getAdvisePaymentInvoiceDetails', ap_payment_advice_hdr_id)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.paymentInvoiceData = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },

  }
};

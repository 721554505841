import { BasicSelect, ModelSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import party from '@/app/components/generalLedger/party';
import PaymentInvoice from './paymentInvoice';
import store from '../../../../../store';
export default {
  name: 'AdviceStatus',
  components: {
    BasicSelect,
    ModelSelect,
    DatePicker,
    party,
    PaymentInvoice
  },
  watch: {
    perPage: function() {
      this.currentPage = 1;
      this.getAdviseStatusGrid();
    },
    currentPage: function() {
      this.getAdviseStatusGrid();
    }
  },
  data() {
    return {
      unsubscribe: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      showPaymentInvoiceDtls: false,
      showValueSetModal: false,
      loader: false,
      currentPage: 1,
      selectLegalEntity: {
        text: null,
        value: null
      },
      leList: [],
      selectOuName: {
        text: null,
        value: null
      },
      ouList: [],
      bankName: '',
      bankAcc: null,
      fromCheckDate: null,
      toCheckDate: null,
      chequeFromDateTodate: [],
      supplierNum: null,
      supplierName: null,
      supplierType: null,
      supplierId: null,
      showPartyModal: false,
      ap_payment_batch_name: '',
      paymentRefNo: null,
      paymentAdvNo: null,
      paymentGroup: {
        value: null,
        text: null
      },
      paymentAdviceRowDtl: null,
      paymentAdviceData: [],
      paymentAdviceFields: [
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'payment_advice_no'
        },
        {
          key: 'payment_ref_no',
          label: 'Payment Ref No.'
        },
        {
          key: 'pay_group_vset_meaning',
          label: 'Pay Group'
        },
        {
          key: 'ap_payment_batch_name',
          label: 'Payment Batch Name'
        },
        {
          key: 'payment_date'
        },
        {
          key: 'supplier_name'
        },
        {
          key: 'supplier_num',
          label: 'Supplier No.'
        },
        {
          key: 'currency'
        },
        {
          key: 'amount'
        },
        {
          key: 'mail_sent'
        },
        {
          key: 'email_address'
        },
        {
          key: 'bank_name',
          label: 'Bank Name'
        },
        {
          key: 'ac_no',
          label: 'Bank AC No.'
        },
        {
          key: 'email'
        }
      ],
      payload: null,
      showAlert: false,
      responseMsg: '',
      isSuccess: false,
    };
  },
  mounted() {
    this.getOracleLeList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download' && !this.showPaymentInvoiceDtls) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'adviceStatus/getAdviseStatusGrid',
            'payment-status',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getOracleLeList() {
      const payload = {
        valueSetName: appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST,
        dependentVariable: {}
      };
      payload.dependentVariable.user_id = store.state.auth.userId;
      payload.dependentVariable.res_id = store.state.shared.responsibilityId;
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data.data;
            const leagalEntity = results.map(type => {
              return {
                value: type.org_id,
                text: type.value_code
              };
            });
            this.leList = leagalEntity;
            this.selectedLegalEntity =
              leagalEntity && leagalEntity.length === 1
                ? leagalEntity[0]
                : this.selectedLegalEntity;
          }
        });
    },
    getOuByLegalId(event) {
      this.selectLegalEntity = event;
      this.getOracleOuList(this.selectLegalEntity.value);
    },
    getOracleOuList(leId) {
      const payload = {
        valueSetName: appStrings.VALUESETTYPE.OU_LIST,
        dependentVariable: {}
      };
      payload.dependentVariable.le_id = leId;
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          const results = response.data.data.data;
          this.ouList = results.map(item => {
            return {
              value: item.org_id,
              text: item.value_code
            };
          });
        });
    },
    getAdviseStatusGrid() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.selectLegalEntity.value,
        ou_id: this.selectOuName.value,
        bank_name: this.bankName,
        ac_no: this.bankAcc,
        payment_from_date: this.chequeFromDateTodate[0],
        payment_to_date: this.chequeFromDateTodate[1],
        entity_type: this.supplierType,
        supplier_num: this.supplierNum,
        ap_payment_batch_name: this.ap_payment_batch_name,
        payment_ref_no: this.paymentRefNo,
        payment_advice_no: this.paymentAdvNo,
        pay_group_vset: this.paymentGroup.value,
        entity_id: this.supplierNum
      };
      this.loader = true;
      this.$store
        .dispatch('adviceStatus/getAdviseStatusGrid', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.paymentAdviceData = response.data.data.page;
            this.paymentAdviceData = this.paymentAdviceData.map(data => {
              // data.select = false;
              data.email = 'Resend';
              return data;
            });
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSelectedPartyCustomer(item) {
      // To select party customer or vendor
      this.supplierName = item.party_name;
      this.supplierType = item.party_type;
      this.supplierId = item.party_id;
      this.showPartyModal = false;
      if (item.party_type === 'CUSTOMER') {
        this.getCustomerByPartyId(item.party_id);
      } else if (item.party_type === 'VENDOR') {
        this.getPartyVendorDetails(item.party_id);
      }
    },
    getCustomerByPartyId(partyId) {
      // To get customer
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.supplierNum = response.data.data.customer_id;
          }
        });
    },
    getPartyVendorDetails(partyId) {
      // To get vendor
      this.$store
        .dispatch('party/getPartyVenderSiteList', partyId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.supplierNum = response.data.data.vendor_id;
          }
        });
    },
    showHidePartyModal(flag) {
      // To open party modal
      this.showPartyModal = flag;
      this.setCustCode = setTimeout(() => {
        this.eventBus.$emit('userType', 'CUST');
      }, 0);
    },
    rowSelected(item) {
      this.paymentAdviceRowDtl = item;
      this.showHidePaymentInvoiceModal(true);
    },
    showHidePaymentInvoiceModal(flag) {
      this.showPaymentInvoiceDtls = flag;
    },
    clearFilters() {
      this.selectLegalEntity = {
        text: null,
        value: null
      };
      this.selectOuName = {
        text: null,
        value: null
      };
      this.bankName = '';
      this.bankAcc = null;
      this.fromCheckDate = null;
      this.toCheckDate = null;
      this.supplierNum = null;
      this.supplierName = null;
      this.ap_payment_batch_name = null;
      this.paymentRefNo = null;
      this.paymentAdvNo = null;
      this.paymentGroup = {
        value: null,
        text: null
      };
      this.ouList = [];
      this.chequeFromDateTodate = [];
      this.paymentAdviceData = [];
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.PAY_GROUP_TYPE) {
        this.paymentGroup = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode){
          switch(vsetCode){
          case this.paymentGroup.value:
          this.paymentGroup = {
            text: null,
            value: null
          }
          }
    },
    resendEmail(ap_payment_advice_hdr_id) {
      this.loader = true;
      this.$store
        .dispatch('adviceStatus/resendEmailForPaymentAdvice', ap_payment_advice_hdr_id)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
